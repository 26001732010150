<template>
  <Modal
    v-if="!this.softLoggedIn && !success"
    :open="open"
    :label="$t('soft_registration_form.title')"
    class="soft-registration-form"
    name="soft-registration-form"
    v-on="$listeners"
    @close="() => $emit('close')"
  >
    <header class="soft-registration-form__header">
      <div class="soft-registration-form__header__badge">
        <Icon name="route" size="2.5rem" />
      </div>
      <p class="soft-registration-form__header__title">
        {{ $t('soft_registration_form.header.title') }}
      </p>
      <p class="soft-registration-form__header__message">
        {{ $t('soft_registration_form.header.message') }}
      </p>
    </header>

    <Notification
      v-if="error"
      :message="$t(`messages.error.${error}.message`)"
      :title="
        $te(`messages.error.${error}.title`) ? $t(`messages.error.${error}.title`) : undefined
      "
      displayed
      error
      inline
      class="soft-registration-form__error"
      @close="() => (error = '')"
    />

    <ValidationObserver v-slot="{ validate }">
      <form class="soft-registration-form__form" @submit.prevent="onSubmit(validate)">
        <ValidationProvider
          v-slot="{ touched, errors }"
          :name="$t('soft_registration_form.name')"
          :skip-if-empty="false"
          rules="required|max:40"
        >
          <Input
            v-model="formState.name"
            :error="touched ? errors[0] : null"
            :label="`${$t('soft_registration_form.name')}`"
            :placeholder="$t('soft_registration_form.name_placeholder')"
            data-cy="name-input"
            :maxlength="40"
            name="name"
            required
          />
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ touched, errors }"
          :name="$t('soft_registration_form.last_name')"
          :skip-if-empty="false"
          class="last-name-input"
          rules="required|max:80"
        >
          <Input
            v-model="formState.lastName"
            :error="touched ? errors[0] : null"
            :label="`${$t('soft_registration_form.last_name')}`"
            :placeholder="$t('soft_registration_form.last_name_placeholder')"
            data-cy="last-name-input"
            :maxlength="80"
            name="last_name"
            required
          />
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ touched, errors }"
          :name="$t('soft_registration_form.second_last_name')"
          :skip-if-empty="false"
          class="second-last-name-input"
          rules="max:80"
        >
          <Input
            v-model="formState.secondLastName"
            :error="touched ? errors[0] : null"
            :label="`${$t('soft_registration_form.second_last_name')}`"
            :placeholder="$t('soft_registration_form.second_last_name_placeholder')"
            data-cy="second_last_name-input"
            :maxlength="80"
            name="second_last_name"
          />
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ touched, errors }"
          :skip-if-empty="false"
          :name="$t('soft_registration_form.email')"
          rules="required|email"
          class="full-width-field"
        >
          <Input
            v-model="formState.email"
            :error="touched ? errors[0] : null"
            :label="$t('soft_registration_form.email')"
            :placeholder="$t('soft_registration_form.email_placeholder')"
            name="email"
            type="email"
            append-icon="email"
            required
            data-cy="email-input"
          />
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ errors }"
          :skip-if-empty="false"
          :rules="{ required: { allowFalse: false } }"
          :name="$t('signup.form.terms')"
          class="full-width-field"
        >
          <Checkbox
            v-model="formState.termsOfServiceAccepted"
            name="terms_of_service_accepted"
            size="s"
            :error="errors[0]"
            required
            data-cy="terms-checkbox"
          >
            <span>
              {{ $t('signup.form.terms_use')[0] }}
              <NuxtLink class="link" :to="localePath('use-terms')" target="_blank" rel="noopener">
                {{ $t('signup.form.terms_use')[1] }}
              </NuxtLink>
              {{ $t('signup.form.terms_use')[2] }}
              <NuxtLink
                class="link"
                :to="localePath('privacy-policy')"
                target="_blank"
                rel="noopener"
              >
                {{ $t('signup.form.terms_use')[3] }}
              </NuxtLink>
              {{ $t('signup.form.terms_use')[4] }}
            </span>
          </Checkbox>
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ errors }"
          :skip-if-empty="true"
          :rules="{ required: false }"
          :name="$t('signup.form.communications')"
          class="full-width-field"
        >
          <Checkbox
            v-model="formState.commercialInformationConsented"
            name="commercial_information_consented"
            size="s"
            :error="errors[0]"
            data-cy="ecomm-checkbox"
          >
            <span>{{ $t('signup.form.communications') }}</span>
          </Checkbox>
        </ValidationProvider>

        <button v-show="false" ref="submit" />
      </form>
    </ValidationObserver>

    <template v-slot:actions>
      <Button
        type-theme="primary-light"
        size="large"
        :loading="loading"
        data-cy="soft-registration-form-submit"
        :state="submitButtonState"
        @click="() => onPerformSubmit()"
      >
        {{ $t('soft_registration_form.submit') }}
      </Button>
    </template>
  </Modal>
</template>

<script>
// Dependencies
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapGetters } from 'vuex'

// Components
import Button from '@/components/Button.vue'
import Checkbox from '@/components/Checkbox.vue'
import Icon from '@/components/Icon.vue'
import Input from '@/components/Input.vue'
import Modal from '@/components/Modal.vue'
import Notification from '@/components/Notification.vue'

export default {
  name: 'ModalsSoftRegistrationForm',
  components: {
    Button,
    Checkbox,
    Icon,
    Input,
    Modal,
    Notification,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    info: {
      type: Object,
      required: false,
      default: null,
    },
    open: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      success: false,
      error: false,
      formState: {
        name: '',
        lastName: '',
        secondLastName: '',
        email: '',
        termsOfServiceAccepted: false,
        commercialInformationConsented: false,
      },
    }
  },
  computed: {
    ...mapGetters({
      softLoggedIn: 'auth/softLoggedIn',
      softUser: 'auth/softUser',
    }),
    submitButtonState() {
      return !this.success &&
        !this.loading &&
        this.formState.name &&
        this.formState.lastName &&
        this.formState.email &&
        this.formState.termsOfServiceAccepted
        ? 'enabled'
        : 'disabled'
    },
  },
  methods: {
    onPerformSubmit() {
      this.$refs.submit.click()
    },
    onSubmit(validate) {
      this.loading = true
      this.error = ''

      validate().then((isValid) => {
        if (!isValid) {
          this.error = 'invalid_fields'
          this.loading = false
        } else {
          this.$store.commit('auth/setSoftUser', this.formState)
          this.loading = false
          this.success = true
          this.$emit('submit', this.success ? this.formState : undefined)
        }
      })
    },
    onOpen() {
      this.$store.dispatch('auth/initializeStore')

      if (this.softLoggedIn) {
        this.$emit('submit', this.softUser)
      }
    },
  },
  watch: {
    open: {
      handler() {
        if (this.open) {
          this.onOpen()
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (this.open) {
      this.onOpen()
    }
  },
}
</script>

<style lang="scss" scoped>
.soft-registration-form :deep(.soft-registration-form__header) {
  display: flex;
  flex-direction: column;
  align-items: center;

  .soft-registration-form__header__badge {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5rem;
    height: 5rem;
    margin-bottom: 1.25rem;
    border-radius: 50%;
    background-color: $c-neutral-300;

    .icon {
      transform: rotate(90deg);
      color: $c-primary-500;
    }
  }

  .soft-registration-form__header__title {
    margin-bottom: 2.5rem;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.75rem;
    text-align: center;
  }

  .soft-registration-form__header__message {
    align-self: flex-start;
    margin: 0 0 1rem;
  }
}

.soft-registration-form :deep(.modal) {
  @include size-m-up {
    width: 100% !important;
  }

  @include size-l-up {
    width: 40.125rem !important;
  }

  @include size-xl-up {
    width: 50rem !important;
  }

  @include size-xxl-up {
    width: 55.5rem !important;
  }
}

.soft-registration-form :deep(.soft-registration-form__error) {
  margin-bottom: 1.5rem;
}

.soft-registration-form :deep(.soft-registration-form__form) {
  display: grid;
  grid-column-gap: 2.5rem;
  grid-row-gap: 0.5rem;
  grid-template-columns: 1fr 1fr;
  width: 100%;

  *.full-width-field {
    grid-column: 1 / -1 !important;
  }

  @include size-xl-up {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include size-l {
    .last-name-input,
    .second-last-name-input {
      grid-row: 2;
    }
  }
  @include size-s {
    grid-template-columns: 1fr;
    .last-name-input,
    .second-last-name-input {
      grid-row: initial;
    }
  }

  .input-wrapper {
    margin-top: 0 !important;
  }

  .checkbox-container {
    margin-top: 1rem !important;
  }
}
</style>
